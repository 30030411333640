import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["expander"]

  connect() {
    this.minExpanderHeightToScroll = 0
  }

  // detail should include:
  //   extraHeightNeeded: the extra height needed so that the element has enough room to
  //                      fit fully within the window.
  //   afterCallback: (optional) callback function that should be called after the
  //                  scrolling has completed.
  inflate({ detail }) {
    // This depends on correct information from the event sender, indicating the exact
    // extra space an element in a vertically scrolling container would need in order
    // to be fully in view. In some cases, where the size needed gradually decreases,
    // a negative value will be returned. In those cases, we don't need to force scrolling
    // because it should already be there. Instead, we just add the negative height.
    if(detail.extraHeightNeeded > 0) {
      this.forceScroll()
      const totalExpanderHeight = Math.ceil(this.minExpanderHeightToScroll + detail.extraHeightNeeded)
      $(this.expanderTarget).height(totalExpanderHeight)
      const newScrollPosition = Math.ceil($(this.element).scrollTop() + totalExpanderHeight)
      $(this.element).scrollTop(newScrollPosition)

      // Fire off a method that can be defined by the initiator of this event that
      // should be called after inflating and adjusting the scroll position.
      if(detail.afterCallback) { detail.afterCallback() }
    } else {
      // TODO: There's still a problem here somewhere that can cause all of the inflation to be
      //       lost when the height needed reduces. This is more easily reproduced when closing
      //       a lookup with some selections, then reopening it and removing some elements, but
      //       not always.
      const reducedHeight = Math.max($(this.expanderTarget).height() + detail.extraHeightNeeded, 0)
      $(this.expanderTarget).height(reducedHeight)
      if(detail.afterCallback) { detail.afterCallback() }
    }
  }

  // Keep adding height until we start scrolling and return the height value at that point
  forceScroll() {
    while(this.element.scrollHeight <= this.element.clientHeight) {
      $(this.expanderTarget).height($(this.expanderTarget).height() + 1)
    }

    this.minExpanderHeightToScroll = $(this.expanderTarget).height()
  }

  deflate() {
    $(this.expanderTarget).height(0)
  }
}

/*
  TODO: What if instead of trying to maintain a state of inflation and try to add/subtract
        from it reliably, we instead *always* re-inflated from zero and repositioned until
        the given element we're repositioning reaches the point where it's all in view? This
        would be a lot more dependent on the repositioning code working correctly (easier to
        fall into infinite loops if it doesn't) and might be less performant. But it might
        be easier to manage and reason about.
*/
