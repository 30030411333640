import { Controller } from "@hotwired/stimulus";
import { debounce } from 'lodash';

export default class extends Controller {
  static targets = [
    "submenu", "submenuItem", "menuPanel", "searchPanel", "searchInput", "searchResults", "searchResultItem",
    "flyout", "flyoutHeader", "flyoutInfo"
  ]
  static values = {
    selected: Object,
    searchData: Object
  }

  connect() {
    this.debouncedSearch = debounce(this.performSearch, 300);
    this.dropdownParent = $(this.element).closest(".db-action-dropdown")[0]
    this.currentFocusIndex = -1;
    this.boundHandleKeyDown = this.handleKeyDown.bind(this);
    this.loading = false

    let self = this

    document.addEventListener("keydown", this.boundHandleKeyDown);

    if (this.dropdownParent) {
      $(this.dropdownParent).on('hidden.bs.dropdown', (e) => {
        // Only hide search view when not loading. Otherwise it may be confusing
        if (!self.loading) {
          this.hideFlyout()
          this.clearSearchInput()
        }
      })
    }
  }

  disconnect() {
    document.removeEventListener("keydown", this.boundHandleKeyDown);
  }

  // On connect, reset the focus index
  searchResultsTargetConnected() {
    this.currentFocusIndex = -1;
  }

  handleKeyDown(event) {
    if (this.searchPanelTarget.classList.contains("loading") || this.loading) return;

    if (this.searchPanelTarget.checkVisibility()) {
      if (event.key === "ArrowDown" || event.key === "ArrowUp" || event.key === "Tab") {
        event.preventDefault();
        this.navigateResults(this.isDownNav(event));
      }
    }
  }

  navigateResults(isDown) {
    const items = this.searchResultItemTargets;
    $(items[this.currentFocusIndex]).removeClass("focus")

    // If no items are available, do nothing
    if (items.length === 0) return;

    // Determine the new index
    if (this.currentFocusIndex === -1) {
      this.currentFocusIndex = isDown ? 0 : items.length - 1; // Start at the first/last item
    } else {
      this.currentFocusIndex += isDown ? 1 : -1;

      // Wrap around if reaching the end or start of the list
      if (this.currentFocusIndex >= items.length) {
        this.currentFocusIndex = 0;
      } else if (this.currentFocusIndex < 0) {
        this.currentFocusIndex = items.length - 1;
      }
    }

    // Using a focus class so that the css selector can reference the item. It appears Safari doesn't
    // handle the native :focus selector very well, or at all
    $(items[this.currentFocusIndex]).addClass("focus");
    items[this.currentFocusIndex].focus(); // Set focus to the new item
    items[this.currentFocusIndex].scrollIntoView({ block: "nearest" }); // Ensure visibility
  }

  // Fired on focus of an item. Focused menu item index and flyout are updated.
  menuItemFocus(event) {
    this.updateFocusIndex(event)
    this.showFlyout(event)
  }

  updateFocusIndex(event) {
    const focusedElement = event.currentTarget;
    this.currentFocusIndex = this.searchResultItemTargets.indexOf(focusedElement);
  }

  openSubmenu(e) {
    e.preventDefault()

    const dataContainer = $(e.target).closest('[data-menu-key]')
    const menuKey = dataContainer.data('menu-key')
    const menuLevel = dataContainer.data('menu-level')
    // TODO: This would need a little work to support more than 2 levels of sub-menus. It
    //       currently assumes that you only need to clear subsequent active open menus if
    //       you make a new selection at level 1. The consequence is subtle. If this logic
    //       gets messed up, we would see certain level links looking active upon returning
    //       to menus that got closed because we selected on at a lower level. A more careful
    //       refactoring effort would be needed if we wanted to substantially change the
    //       logic here. The goal of this current version is only to move to a nested menu
    //       that doesn't need server calls to navigate sub-menus. If doing a larger refactor,
    //       consider using something more recursive and/or smaller stimulus controllers for
    //       each level.
    let newSelected = { ...this.selectedValue, [menuLevel]: menuKey }
    if(menuLevel == 0) {
      newSelected = { ...newSelected, 1: null }
    }
    this.selectedValue = newSelected
    this.submenuTargets.forEach((element, _index) => {
      if($(element).data('menu-level') >= menuLevel) {
        element.hidden = $(element).data('menu-key') != menuKey
      }
    })
  }

  selectedValueChanged() {
    this.submenuItemTargets.forEach((element, _index) => {
      for (const [level, key] of Object.entries(this.selectedValue)) {
        if($(element).data('menu-level') == level) {
          if($(element).data('menu-key') == key) {
            $(element).addClass('active');
          } else {
            $(element).removeClass('active');
          }
        }
      }
    })
  }

  searchItems(event) {
    let searchTerm = event.target.value;

    // Apply loading state to prep for search results
    this.searchPanelTarget.classList.add("loading");

    // Hide flyout when searching
    this.hideFlyout()

    if (searchTerm === "") {
      this.searchPanelTarget.style.display = "none";
      $(this.menuPanelTarget).show();
    } else {
      $(this.menuPanelTarget).hide();
      this.searchPanelTarget.style.display = "flex";

      this.debouncedSearch(searchTerm);
    }
  }

  performSearch(searchTerm) {
    const params = { ...this.searchDataValue.params, q: searchTerm };
    const urlParams = new URLSearchParams(params).toString();
    const headers = {
      ...this.searchDataValue.headers,
      "Accept": "text/html",
      "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
    };

    fetch(`${this.searchDataValue.fetch_url}?${urlParams}`, {
      headers: headers,
    })
      .then(response => response.text())
      .then(html => {
        const target = this.searchResultsTarget;

        if (target) {
          target.outerHTML = html;
        }
      })
      .catch(error => {
        // TODO: Handle errors?
      })
      .finally(() => {
        this.searchPanelTarget.classList.remove("loading");
      });
  }

  clearSearchInput() {
    this.searchInputTarget.value = "";
    $(this.searchPanelTarget).hide();
    $(this.menuPanelTarget).show();
  }

  showFlyout(event) {
    $(this.flyoutInfoTargets).removeClass("active")

    $(this.flyoutTarget).addClass("active")
    $(this.flyoutHeaderTarget).addClass("active")
    let flyoutKey = $(event.currentTarget).data("flyout-key")
    let flyoutInfo = this.flyoutInfoTargets.find((info) => $(info).data("flyout-key") == flyoutKey)
    $(flyoutInfo).addClass("active")
  }

  hideFlyout() {
    $(this.flyoutTarget).removeClass("active")
    $(this.flyoutInfoTargets).removeClass("active")
    $(this.flyoutHeaderTarget).removeClass("active")
  }

  fetchMenuItem(event) {
    $(this.element).addClass("loading")
    $(event.currentTarget).addClass("fetching")
    this.loading = true

    $(document).one("turbo:before-fetch-response", (e) => {
      this.postFetchMenuItem(e)
    })
  }

  postFetchMenuItem(event) {
    $(this.element).removeClass("loading")
    $(this.element).find(".fetching").removeClass("fetching")
    this.loading = false
  }

  isDownNav(event) {
    return event.key === "ArrowDown" || (!event.shiftKey && event.key === "Tab")
  }
}
