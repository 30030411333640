import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tooltip"
export default class extends Controller {
  static targets = ["observe", "selector", "truncatedText"]
  static values = {
    onlyOnTruncation: Boolean
  }

  initialize() {
    this.defaultOptions = {
      html: true,
      boundary: "window",
      offset: "0px, 0px",
      trigger: $(this.element).data("trigger") || "hover focus",
      placement: $(this.element).data("placement") || "top",
      delay: $(this.element).data("delay") || 0
    }

    this.tooltip
  }

  connect() {
    if (!this.hasSelectorTarget) {
      // For case when we want to attach the tooltip to a certain element that we couldn't wrap without
      // interrupting the html structure, we'll initialize on the selector instead, else on the element
      // E.g. wrapping around the actionDropdownComponent button
      $(this.element).tooltip(this.defaultOptions)
      this.tooltip = this.element
    }

    this.setup()
  }

  selectorTargetConnected() {
    $(this.selectorTarget).tooltip(this.defaultOptions)
    this.tooltip = this.selectorTarget
  }

  updateTitle(title) {
    $(this.tooltip).attr('data-original-title', title)
  }

  rotateThroughTitles() {
    let titles = $(this.element).data('titles-rotation')
    let currentTitleIndex = titles.indexOf($(this.tooltip).attr('data-original-title'))
    let maxIndex = titles.length

    let nextTitleIndex = 0

    if ((currentTitleIndex + 1) < maxIndex) {
      nextTitleIndex = currentTitleIndex + 1
    }

    this.updateTitle(titles[nextTitleIndex])

    $(this.tooltip).tooltip("hide")
    $(this.tooltip).tooltip("show")
  }

  setupObservation() {
    if (!this.hasObserveTarget) return

    // Create a new mutation observer instance
    this.observeTarget.observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          if (mutation.target.textContent === $(this.element).data("observe-ignore-text")) {
            this.updateTitle("")
          } else {
            this.updateTitle(mutation.target.textContent)
          }
        }
      }
    });

    // Configure and start observing the target node
    let config = { childList: true, subtree: true }
    
    this.observeTarget.observer.observe(this.observeTarget, config)
  }

  checkTruncation() {
    if (this.onlyOnTruncationValue && this.hasTruncatedTextTarget) {
      $(this.tooltip).on("show.bs.tooltip", (event) => {
        let truncatedText = this.truncatedTextTarget
        let isTruncated = truncatedText.scrollHeight > truncatedText.clientHeight || truncatedText.scrollWidth > truncatedText.clientWidth
        if (!isTruncated) {
          // call preventDefault to prevent the default behaviour of the tooltip of showing when the string is not truncated
          event.preventDefault()
        }
      })
    }
  }

  setup() {
    this.setupObservation()
    this.checkTruncation()
  }

  hide() {
    $(this.tooltip).tooltip("hide");  
  }

  tearDownObservation() {
    if (!this.hasObserveTarget) return

    this.observeTarget.observer.disconnect()
  }

  disconnect() {
    this.tearDownObservation()
    $(this.tooltip).tooltip("dispose")
  }
}
