import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    let frame = $(this.element).closest("turbo-frame")[0]

    if (frame?.scrollPositions) {
      let menus = $(this.element).find('ul')
      menus.each((index, menu) => {
        $(menu).scrollTop(frame.scrollPositions[index])
      })
    }
  }

  saveScrollPositions(e) {
    let frame = $(this.element).closest("turbo-frame")[0]
    if (frame) {
      frame.scrollPositions = {}
      $(this.element).find('ul').each((index, menu) => {
        frame.scrollPositions[index] = menu.scrollTop
      })
    }
  }
}
