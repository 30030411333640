// loading_controller.js
// Adds a "loading" class to specified elements when a turbo fetch request is made, and removes the class when the response is received.
// Ensures that a minimum time duration (which can be provided) is met before hiding the loading state.
// It should be possible only need one instance of this controller per page.
//
// Data/Value Attributes:
// - data-loading-id
//     An identifier that indicates an element that the loading class can be applied to.
// - data-loading-target
//     Corresponds to an existing loading ID. This attribute should be attached to links that make turbo fetch requests.
//     When a fetch request is made, the loading class will be added to the elements with the corresponding loading ID.
// - minDuration
//    Optional. The minimum duration in milliseconds that the loading state should be shown. If not provided, the default is 500ms.
//
// Some potential improvements:
// - Instead of targetting a data-loading-id, we could target element classes directly (no longer need to specify extra data).
// - Automatically attach this controller to the body of all platform pages, so it's always present and at a high level in the DOM.
// - Attach minDuration to the individual loading targets, and not the loading controller itself.

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  static values = { id: String, minDuration: Number };

  connect() {
    document.addEventListener("turbo:before-fetch-request", this.startLoading.bind(this));
    document.addEventListener("turbo:before-fetch-response", this.handleResponse.bind(this));
  }

  disconnect() {
    document.removeEventListener("turbo:before-fetch-request", this.startLoading.bind(this));
    document.removeEventListener("turbo:before-fetch-response", this.handleResponse.bind(this));
  }

  // When a turbo fetch request is made, determine if a loading state is needed. See if there's a
  // loading target attached to the event target, and if so, show the loading state there.
  startLoading(event) {
    let loadingTargets = event.target.dataset.loadingTarget;
    if (!loadingTargets) return;

    // loadingTargets can be a single string or an array of strings.
    try {
      loadingTargets = JSON.parse(loadingTargets);
    } catch (e) {
      loadingTargets = [loadingTargets];
    }

    loadingTargets.forEach((loadingId) => {
      const loaders = document.querySelectorAll(`[data-loading-id="${loadingId}"]`);
      if (loaders.length === 0) return;

      loaders.forEach((loader) => {
        loader.dataset.loadingStart = Date.now();
        loader.classList.add("loading");
      });
    });
  }

  // When a turbo response is received, determine if a loading state should be hidden.
  // See if there's a loading target attached to the event target, and if so, hide the loading state there.
  // The loading state can show for a specified minimum duration.
  handleResponse(event) {
    let loadingTargets = event.target.dataset.loadingTarget;
    if (!loadingTargets) return;

    // loadingTargets can be a single string or an array of strings.
    try {
      loadingTargets = JSON.parse(loadingTargets);
    } catch (e) {
      loadingTargets = [loadingTargets];
    }

    loadingTargets.forEach((loadingId) => {
      const loaders = document.querySelectorAll(`[data-loading-id="${loadingId}"]`);
      if (loaders.length === 0) return;

      loaders.forEach((loader) => {
        const minDuration = parseInt(loader.dataset.loadingMinDuration) || 500;
        const startTime = parseInt(loader.dataset.loadingStart) || Date.now();
        const elapsedTime = Date.now() - startTime;
        const remainingTime = Math.max(0, minDuration - elapsedTime);

        setTimeout(() => {
          loader.classList.remove("loading");
        }, remainingTime);
      });
    });
  }
}