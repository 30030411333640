import { Turbo } from "@hotwired/turbo-rails"
import "shared_ui/controllers"
import { gaPush } from "shared_ui/analytics"

window.gaPush = gaPush

// Set Turbo Drive to be opt-in only on forms
// To opt-in, set data-turbo="true" on the form
// See https://github.com/omxhealth/drugbank/pull/3220 for more details
Turbo.config.forms.mode = "optin"
