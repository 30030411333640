function gaPush(event, parameters) {
  // This is mostly just a helper to push GA events and reset the datalayer values if we do not need them to persist
  dataLayer.push({
    'event': event,
    ...parameters
  })

  // Reset datalyer values that were pushed
  let undefinedParameters = {}

  for (let key in parameters) {
    undefinedParameters[key] = undefined
  }

  dataLayer.push(undefinedParameters)
}

export { gaPush }
