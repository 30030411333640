import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

// Grab existing Stimulus application or start a new one up
const application = window.Stimulus || Application.start()

const context = require.context("../controllers", true, /_controller\.js$/)
const contextComponents = require.context("../../../components", true, /_controller\.js$/)

application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents)
  )
)

// Configure Stimulus development experience if we never had an application to start with
if (!("Stimulus" in window)) {
  application.debug = false
  window.Stimulus = application
}

export { application }
