import { Controller } from "@hotwired/stimulus"
import { debounce } from 'lodash'

// Connects to data-controller="side-bar"
export default class extends Controller {
  static targets = ["content", "controls", "container", "header", "footer"]

  initialize() {
    this.lowestElement = $(this.contentTarget)
  }

  connect() {
    this.setup()
  }

  toggle() {
    if (!this.isDisabled()) {
      $(this.element).toggleClass("collapsed")
    }

    // Hide popovers on collapse, and reshow on expand.
    // For some weird reason, using .popover("show") here
    // would hide popovers outside of the sidebar :(
    if ($(this.element).hasClass("collapsed")) {
      $(this.element).find(".db-popover").each(function() {
        let popover_id = $(this).attr('aria-describedby')
        $("#" + popover_id).hide()
      })
    } else {
      // Pull timeout directly from the sidebar transition. Might this change if the sidebar uses multiple transitions?
      let timeout_time = parseFloat($(this.element).css("transition-duration")) * 1000

      $(this.element).find(".db-popover").each(function() {
        setTimeout(() => {
          let popover_id = $(this).attr('aria-describedby')
          $("#" + popover_id).show()
        }, timeout_time)
      });
    }
  }

  isDisabled() {
    return $(this.element).hasClass("disabled")
  }

  isCollapsed() {
    return $(this.element).hasClass("collapsed")
  }

  isExpanded() {
    return !this.isDisabled() && !this.isCollapsed()
  }

  setupContentMaxHeightEvent() {
    this.prevContentHeight = $(this.contentTarget).outerHeight()

    $(this.contentTarget).css("max-height", `min(calc(100vh - ${this.viewHeightOffset()}px), ${$(this.element).height()}px)`)

    let _this = this

    const elementResizeObserver = new ResizeObserver(debounce((entries) => {
      for (const entry of entries) {
        if (($(_this.contentTarget).outerHeight() != _this.prevContentHeight)) {
          $(_this.contentTarget).css("max-height", `min(calc(100vh - ${_this.viewHeightOffset()}px), ${$(_this.element).height()}px)`)
          _this.prevContentHeight = $(_this.contentTarget).outerHeight()
          _this.castShadow()
        }
      }
    }))

    elementResizeObserver.observe(this.element);  
  }

  // Only cast box shadow in footer if content overflows
  setupShadowObserver() {
    let _this = this

    const elementResizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        _this.castShadow()
      }
    })

    elementResizeObserver.observe($(this.contentTarget).find(".db-sidebar__content__wrapper")[0])
  }

  isElementVisible(element) {
    // An element is considered visible if it has a display set and has an offset parent.
    // Browser detection from https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browsers
    // mozInnerScreenX in Firefox returns a value when all other browsers return null. Will have to handle it separately.
    let isFirefox = typeof mozInnerScreenX !== 'undefined'

    return !(($(element)?.css("display") == "") || !element.offsetParent || (isFirefox && element.offsetParent === $('body')[0]))
  }

  castShadow() {
    if ($(this.contentTarget).find(".db-sidebar__content__wrapper").height() > $(this.contentTarget).height()) {
      $(this.footerTarget).find(".db-sidebar__footer__wrapper").addClass("shadow")
    } else {
      $(this.footerTarget).find(".db-sidebar__footer__wrapper").removeClass("shadow")
    }
  }

  anchorTopOffset() {
    return $($(this.element).data("anchor-element")).offset()?.top || 0
  }

  viewHeightOffset() {
    return this.anchorTopOffset() + $(this.headerTarget).outerHeight(true) + $(this.footerTarget).outerHeight(true)
  }

  setup() {
    this.setupContentMaxHeightEvent()
    this.setupShadowObserver()
  }
}
