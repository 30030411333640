import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="side-panel"
export default class extends Controller {
  static targets = ["gutter", "content", "body", "history", "back"]

  // active: Open/close state of the side panel
  // history: Queue of urls that the side panel can navigate to
  // currentHistoryIndex: Index of where we are in the history queue
  static values = {
    active: Boolean,
    history: { type: Array, default: [] },
    currentHistoryIndex: { type: Number, default: -1 },
    historyToken: String
  }

  initialize() {
    this.resizing = false

    // storeHistory is a flag that identifies when to store urls to the history queue
    this.storeHistory = true

    this.resizeHandler = this.resize.bind(this)
    this.stopResizingHandler = this.stopResizing.bind(this)
    this.checkMinWidthStateHandler = this.checkMinWidthState.bind(this)
  }

  connect() {
    // Load session history
    const storedHistory = this.getSidePanelHistory();
    const sessionHistory = storedHistory[this.historyTokenValue];
    var contentUrl;

    // If history is stored in the session, load it into controller values and fetch content
    if (sessionHistory) {
      this.historyValue = sessionHistory.history || [];
      this.currentHistoryIndexValue = Number.isInteger(sessionHistory.currentHistoryIndex)
        ? sessionHistory.currentHistoryIndex
        : -1;

      contentUrl = this.historyValue[this.currentHistoryIndexValue]

      if (URL.canParse(contentUrl)) {
        this.fetchContent(contentUrl, true)
      }
    }

    if (this.activeValue || URL.canParse(contentUrl)) {
      this.open(false)
    } else {
      this.hide(false)
    }

    this.setupBodyObserver()
    $(window).on("resize", this.checkMinWidthStateHandler)

    if (this.currentHistoryIndexValue < 1) {
      $(this.backTarget).hide()
    }
  }

  // Returns the side panel history from session storage
  getSidePanelHistory() {
    const history = sessionStorage.getItem("side_panel_history");
    return history ? JSON.parse(history) : {};
  }

  // Updates the session storage history with the current history values
  updateSidePanelHistory() {
    const storedHistory = this.getSidePanelHistory();
    storedHistory[this.historyTokenValue] = {
      history: this.historyValue,
      currentHistoryIndex: this.currentHistoryIndexValue,
    };
    sessionStorage.setItem("side_panel_history", JSON.stringify(storedHistory));
  }

  // Deletes the side panel history (for the current query) from session storage
  deleteSidePanelHistory() {
    const storedHistory = this.getSidePanelHistory();
    delete storedHistory[this.historyTokenValue];
    sessionStorage.setItem("side_panel_history", JSON.stringify(storedHistory));

    this.historyValue = [];
    this.currentHistoryIndexValue = -1;
    $(this.backTarget).hide()
  }

  // Turbo stream request to get content for the side panel. Hiding the back button while fetching content is optional.
  fetchContent(url, hideBack = false) {
    if (hideBack) {
      $(this.backTarget).hide()
    }

    fetch(url, {
      headers: {
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
        "Accept": "text/vnd.turbo-stream.html"
      }
    })
      .then(response => response.text())
      .then(text => Turbo.renderStreamMessage(text))
      .finally(() => {
        if (hideBack && this.currentHistoryIndexValue > 0 && this.historyValue.length > 0) {
          $(this.backTarget).show()
        }
      })
  }

  // Side panel turbo streamed content that should be stored is expected to have a meta element
  // in the side panel's history frame
  historyTargetConnected(element) {
    // For cases where new content is getting rendered because of us going back, we don't want to store
    // the url. Otherwise we end up in this loop of going back and storing it again
    if (!this.storeHistory) {
      if (this.currentHistoryIndexValue < 1) {
        $(this.backTarget).hide()
      }

      this.storeHistory = true
      return 
    }

    let renderedUrl = element.dataset.sidePanelUrl

    // Do not store urls that we are already on
    if (renderedUrl === this.historyValue[this.currentHistoryIndexValue]) {
      return
    }

    // Truncate forward history if navigating from the middle of the queue. We don't really have the ability to
    // go forward quite yet. But the ability to go forward has been discussed
    if (this.currentHistoryIndexValue < this.historyValue.length - 1) {
      this.historyValue = this.historyValue.slice(0, this.currentHistoryIndexValue + 1)
    }

    // Only store non empty urls.
    if (renderedUrl) {
      // We are using a reassignment here instead of the native push function on Arrays because since the history queue
      // is a Stimulus controller static value, whenever a assignment is done to the value, like the slice function call
      // above, it techniquely calls a callback. So any in place functions will get overriden later on by that callback.
      // We could move the definition of the history queue into the initialize function and do a bit of refactoring 
      // instead. But leaving it as a static value instead for now leaves the option for predefined history queues on
      // the side_panel_component initialization from the server side. But if we later on find that we don't need that
      // option and this gimick of static values is causing more issues than it could solve. We can make that refactor.
      this.historyValue = [...this.historyValue, renderedUrl]
    }

    // Only increment queue index if there is actually stuff in the queue
    if (this.historyValue.length > 0) {
      // We are allowing the queue index to be a max of the queue length because of the case for links we might not want
      // to get stored. We want to set the index to be able to at least decrement back into the history queue
      this.currentHistoryIndexValue = Math.min(this.currentHistoryIndexValue + 1, this.historyValue.length)
    }

    // If we at least are a positive index and have stuff in the queue to go back to, show the back button
    // Cases where the index could be 1 and the queue is empty is if we went to a non storable link as the very 
    // first side panel content
    if (this.currentHistoryIndexValue > 0 && this.historyValue.length > 0) {
      $(this.backTarget).show()
    }

    this.updateSidePanelHistory();
  }

  // Navigate back in the history queue
  back() {
    this.currentHistoryIndexValue--
    this.storeHistory = false

    // Will need to manually make a turbo stream fetch
    // We can't use the Turbo.visit here because we are currently using turbo stream to manage the side panel
    // frame contents
    this.fetchContent(this.historyValue[this.currentHistoryIndexValue], false)

    this.updateSidePanelHistory();
  }

  startResizing(event) {
    event.preventDefault()
    this.resizing = true
    $(this.element).addClass('resizing')
    this.current_x = event.clientX
    $(document).on("mousemove", this.resizeHandler)
    $(document).on("mouseup", this.stopResizingHandler)
  }

  stopResizing(event){
    event.preventDefault()
    this.resizing = false
    $(this.element).removeClass('resizing')
    $(document).off("mousemove", this.resizeHandler)
    $(document).off("mouseup", this.stopResizingHandler)
  }

  resize(event) {
    if (this.resizing) {
      let x_delta = this.current_x - event.clientX
      this.current_x = event.clientX
      $(this.element).width($(this.element).width() + x_delta)

      this.checkMinWidthState()
    }
  }

  setupContentObserver() {
    const config = { childList: true, subtree: true }

    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          this.open()
          this.contentTarget.observer.disconnect()
          break
        }
      }
    };

    this.contentTarget.observer = new MutationObserver(callback);

    this.contentTarget.observer.observe(this.contentTarget, config);
  }

  // Nothing at the moment of writing this comment is using this "bodyChange" event, and it is more of a remanent 
  // of a solution to handle the active state of column headers before it got scrapped. Otherwise, this 
  // bodyChange event can be removed if we can't see a use for it and want to keep things tidy
  // Some future use cases this might be handy is if we add visual active states to components that triggers a
  // content change in the side panel, and we need that active component to deactivate when the body changes
  setupBodyObserver() {
    const config = { childList: true, subtree: true }

    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          this.dispatch("bodyChange")
          break
        }
      }
    };

    this.bodyTarget.observer = new MutationObserver(callback);

    this.bodyTarget.observer.observe(this.bodyTarget, config);
  }

  checkMinWidthState() {
    let parent = $(this.element).parent()
    let minWidth = $(this.element).css("min-width")

    if (minWidth.indexOf("%") >= 0) {
      minWidth = parent.width() * (parseFloat(minWidth)/100)
    } else {
      minWidth = parseFloat(minWidth) || 0
    }

    if (this.element.getBoundingClientRect().width <= minWidth) {
      $(this.element).addClass("min-width")
    } else {
      $(this.element).removeClass("min-width")
    }
  }

  // The lifecycle parameter is used to flag if we want the lifecycle events to trigger
  hide(lifecycle = true) {
    $(this.element).removeClass("active")
    $(this.element).addClass("hidden")
    this.setupContentObserver()

    if (lifecycle) {
      this.dispatch("close")
      $(this.element).one("transitionend", (event) => {
        this.dispatch("closed")
      })
    }

    // Clear the session history when the side panel is closed. This may be changed later on if we want to keep
    // the history for the current query or plan to implement a way to minimize + expand the side panel.
    this.deleteSidePanelHistory()
  }

  open(lifecycle = true) {
    $(this.element).removeClass("hidden")
    $(this.element).addClass("active")

    if (lifecycle) {
      this.dispatch("open")
      $(this.element).one("transitionend", (event) => {
        this.dispatch("opened")
      })
    }
  }

  disconnect() {
    this.contentTarget.observer?.disconnect()
    $(window).off("resize", this.checkMinWidthStateHandler)
  }
}
