import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["formError"];

  connect() {
    $(this.element).modal('show');

    if ($(this.element).data('remove-on-close')) {
      $(this.element).on("hidden.bs.modal", this.removeModal.bind(this));
    }

    if ($(this.element).data('focus-first-input')) {
      $(this.element).on("shown.bs.modal", this.focusFirstInput.bind(this));
    }
  }

  removeModal() {
    $(this.element).remove();
    // In rare cases, several backdrops may be added
    $('.modal-backdrop').remove();
  }

  focusFirstInput() {
    let input = $(this.element).find("input").not("[type='hidden']").first();
    let value = input.val();

    input.focus();

    // Hacky way to make the cursor appear at the end of the input.
    if (input.is('input[type="text"]')) {
      input.val('').val(value)
    }
  }

  submitEnd(e) {
    if (e.detail.success) {
      $(this.element).modal('hide');
    }
  }
}
